import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import Map from './map/Map';

// Sidebar : https://reacttraining.com/react-router/web/example/sidebar

function App() {
  return (
    <Map/>
  );
  /*
  return (
    <Router>
      <div className="App">
        <div className="App-sidebar">
          <ul>
            <li>
              <Link to="/map">Plan</Link>
            </li>
            <li>
              <Link to="/search">Recherche</Link>
            </li>
          </ul>
        </div>
        <div className="App-view">
          <Switch>
            <Route path='/map'>
              <Map/>
            </Route>
            <Route path='/search'>
              <Search/>
            </Route>
          </Switch>
        </div>
      </div>
    </Router>
  );
  /*
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
        <Hello name="Toto"/>
      </header>
      */
}

export default App;

// @flow

import React, { Component } from 'react'
import { Map, Pane, Rectangle, TileLayer, Polygon, Polyline, GeoJSON, Marker, Popup, CircleMarker } from 'react-leaflet'
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

// https://www.data.gouv.fr/fr/organizations/region-des-pays-de-la-loire/
// https://sig.ville.gouv.fr/Cartographie/5201020
// https://www.data.gouv.fr/fr/datasets/perimetre-des-quartiers-prioritaires-politique-de-la-ville/
import * as quartiers_prioritaires from './_perimetre-des-quartiers-prioritaires-politique-de-la-ville'
import * as communes_polygons from './244400404_communes-nantes-metropole-sans-nantes.json'
import * as quartiers_polygons from './244400404_quartiers-nantes'
import * as ecoles_polygons from './244400404_perimetres-scolaires-nantes.json'
import * as ecoles_markers from './244400404_equipements-publics-theme-enseignement-nantes-metropole'

export default class SimpleExample extends React.Component {
  constructor() {
    super();
    this.state = {
      communes_polygons: communes_polygons.default,
      quartiers_polygons: quartiers_polygons.default,
      ecoles_polygons: ecoles_polygons.default,
      ecoles_markers: ecoles_markers.default,
      quartiers_prioritaires: quartiers_prioritaires.default
    };
  }

  defaultStyle() {
    return {
      color: '#0000ff',
      weight: 2,
      fillOpacity: 0
    }
  }

  onEachFeatureCommunesPolygon(feature: Object, layer: Object) {
    const popupContent = `<Popup>
        ${feature.properties.nom}
      </Popup>`;
    layer.bindPopup(popupContent)
  }
  communesStyle() {
    return {
      color: '#303ee9',
      weight: 2,
      fillOpacity: 0
    }
  }

  onEachFeatureQuartiersPrioritairesPolygon(feature: Object, layer: Object) {
    const popupContent = `<Popup>
        ${feature.properties.nom_qp}
      </Popup>`;
    layer.bindPopup(popupContent)
  }
  quartiersPrioritairesStyle() {
    return {
      color: '#e90009',
      weight: 2,
      fillOpacity: 0.5,
      fillColor: '#ff5b32',
    }
  }

  onEachFeatureQuartiersPolygon(feature: Object, layer: Object) {
    const popupContent = `<Popup>
        ${feature.properties.nom}
      </Popup>`;
    layer.bindPopup(popupContent)
  }
  quartiersStyle() {
    return {
      color: '#303ee9',
      weight: 2,
      fillOpacity: 0.5,
      fillColor: '#fff2af',
    }
  }

  onEachFeatureEcolesPolygon(feature: Object, layer: Object) {
    const popupContent = `<Popup>
        ${feature.properties.name}
      </Popup>`;
    layer.bindPopup(popupContent)
  }

  onEachFeatureEcoleMarker(feature: Object, layer: Object) {
    const popupContent = `<Popup>
        ${feature.properties.nom_complet}
      </Popup>`;
    layer.bindPopup(popupContent)
  }
  pointToLayer(feature, latlng) {
    return L.circleMarker(latlng, {
      radius: 5
    }); // Change marker to circle
    // return L.marker(latlng, { icon: {}}); // Change the icon to a custom icon
  }
  ecolesMarkersStyle() {
    return {
      color: '#303ee9',
      weight: 1,
      fillOpacity: 0.5,
      fillColor: '#303ee9',
    }
  }

  render() {
    let DefaultIcon = L.icon({
      iconUrl: icon,
      shadowUrl: iconShadow
    });
    L.Marker.prototype.options.icon = DefaultIcon;

    return (
      <Map
        center={[47.2173, -1.5534]}
        zoom={13}>
        <TileLayer attribution='&copy; <ahref="http://osm.org/copyright">
          OpenStreetMap</a> contributors'
                   url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <GeoJSON
          key='communes_polygons'
          data={this.state.communes_polygons}
          style={this.communesStyle}
          onEachFeature={this.onEachFeatureCommunesPolygon}
        />
        <GeoJSON
          key='ecoles_polygons'
          data={this.state.ecoles_polygons}
          style={this.defaultStyle}
          onEachFeature={this.onEachFeatureEcolesPolygon}
        />
        <GeoJSON
          key='quartiers_prioritaires'
          data={this.state.quartiers_prioritaires}
          style={this.quartiersPrioritairesStyle}
          onEachFeature={this.onEachFeatureQuartiersPrioritairesPolygon}
        />
        <GeoJSON
          key='ecoles_markers'
          data={this.state.ecoles_markers}
          style={this.ecolesMarkersStyle}
          onEachFeature={this.onEachFeatureEcoleMarker}
          pointToLayer={this.pointToLayer.bind(this)}
        />
      </Map>
    );
  }
  /*

        <GeoJSON
          key='quartiers_polygons'
          data={this.state.quartiers_polygons}
          style={this.quartiersStyle}
          onEachFeature={this.onEachFeatureQuartiersPolygon}
        />
        <GeoJSON
          key='quartiers_polygons'
          data={this.state.quartiers_polygons}
          onEachFeature={this.onEachFeatureQuartiersPolygon}
        />

        <GeoJSON
          key='ecoles_polygons'
          data={this.state.ecoles_polygons}
          onEachFeature={this.onEachFeatureEcolesPolygon}
        />

        <GeoJSON
          key='ecoles_markers'
          data={this.state.ecoles_markers}
          onEachFeature={this.onEachFeatureEcoleMarker}
        />

        <GeoJSON key='my-geojson' data={this.state.ecoles_polygons}/>
        <GeoJSON key='my-geojson' data={this.state.ecoles_markers}/>
        {this.state.ecoles_markers.features.map((item, key) => {
            console.log(item.properties.location);
            return <Marker position={item.properties.location} key={item.properties.idobj}>
              <Popup>
                {item.properties.nom_complet}
              </Popup>
            </Marker>
          }
        )}
        */
}